.app-bar {
  background: #ffffff !important;
  box-shadow: 0px 1px 0px rgba(219, 223, 229, 0.6) !important;
}

.logo {
  width: 130px;
  height: 25px;
  margin: 16px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis-line-1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.ow-break-word {
  white-space: normal;
  word-break: normal;
  overflow-wrap: anywhere;
  min-width: 0;
}

.b-radius-top-less .MuiDataGrid-columnHeaders {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.condition-required-label:after {
  background-color: #BFCCD8;
  border-radius: 4px;
  color: white;
  content: "該当必須";
  font-size: 0.625rem;
  margin-left: 4px;
  padding: 2px 4px;
}

.required-label:after {
  background-color: #DD6262;
  border-radius: 4px;
  color: white;
  content: "必須";
  font-size: 0.625rem;
  margin-left: 4px;
  padding: 2px 4px;
}

/**
 * swiper
 */
.swiper.image-carousel {
  max-width: 1050px;
}
.image-carousel .swiper-slide {
  width: 288px;
  flex-shrink: 0;
}
.swiper-button-disabled {
  display: none !important;
}
